import React from "react";

import Header, { Props } from "./Header";
import { useLocation } from "react-router-dom";

function HeaderWrapper(props: Omit<Props, "location">) {
  const location = useLocation();

  return (
    <Header location={location.pathname} {...props} />
  );
}

export default HeaderWrapper;