import React from "react";

export type Props = {
  imgSrc: string;
  imgAlt?: string;
  title: string;
  description: string;
}

function Feature(props: Props) {
  const { imgSrc, imgAlt = "", title, description } = props;

  return (
    <div className="col-md-4 col-sm-6 col-xs-12 col-lg-4">
      <div className="single-feature">
        <div className="feature-inner fix">
          <div className="fe-icon">
            <img src={imgSrc} alt={imgAlt} />
          </div>
          <div className="fe-disc">
            <h5 className="fe-title">{title}</h5>
            <p className="fe-content">{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Feature;
