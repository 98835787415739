import React from "react";

export type Props = {
  img1Src: string;
  img1Alt: string;
  img2Src: string;
  img2Alt: string;
  text: string;
}

function Square(props: Props) {
  const { img1Src, img1Alt, img2Src, img2Alt, text } = props;
  return (
    <div className="single-service">
      <div className="service-bg">
        <img src={img1Src} alt={img1Alt} />
      </div>
      <div className="service-info">
        <img className="service-icon" src={img2Src} alt={img2Alt} />
        <h3>{text}</h3>
      </div>
    </div>
  );
}

export default Square;
