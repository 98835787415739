import React from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { formatMessage } from "../../i18n";

export type Props = {
  backgroundColor: string;
  imgSrc: string;
  imgAlt: string;
  title: string;
  description: string;
  link: string;
}

function Service(props: Props) {

  const { backgroundColor, imgSrc, imgAlt, title, description, link } = props;
  const intl = useIntl();

  return (
    <div className="col-md-5">
      <div className="single-service" style={{ backgroundColor }}>
        <div className="single-service-inner">
          <div className="service-box-front">
            <img src={imgSrc} alt={imgAlt} />
            <h3 className="ser-front-heading">{title}</h3>
          </div>
          <div className="service-box-back">
            <h3 className="ser-back-heading">{title}</h3>
            <p className="ser-back-content">{description}</p>
            <Link to={link} className="ser-link">
              {formatMessage(intl, "home.more.info")}<i className="fa fa-caret-right"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Service;
