import React from "react";

import Breadcrumbs from "../breadcrumbs";
import FooterContact from "../footer-contact";

import { formatMessage } from "../../i18n";
import { useIntl } from "react-intl";

// import BlogEntry from "../blog-entry";

function Campus() {
  const intl = useIntl();

  return (
    <>
      <div className="page-content">
        <Breadcrumbs
          bgClassname="bg-campus"
          pageTitle="Campus vacacionales"
        />

        <div className="col-xs-12 padding-t50 padding-b50 text-center">
          <div className="container">
            <h1 className="font35" style={{ color: "#bfa37c"}}>{formatMessage(intl, "vacation.campus")}</h1>
            <p className="font17" style={{ marginTop: 20 }}>{formatMessage(intl, "vacation.campus.description")}</p>
          </div>
        </div>

        <div className="latest-blog-area blog-page padding50">
          <div className="container">
            <div className="row">
              <div className="blog-post-inner blog-small-image">
                <div className="col-md-12 col-xs-12">
                  {/*
                  <BlogEntry
                    imageSrc="/assets/img/campus/b01.jpg"
                    imageAlt="blog img"
                    title="CAMPUS DE PASCUA 2018"
                    date="14/03/2018"
                    text={[
                      "¿Tienes que trabajar en Semana Santa? ¿Tienes peques menores de 6 años? ¡Tráelos a nuestro Campus de Pascua en Happinenss!",
                      "Esta Semana Santa hemos preparado un montón de actividades y talleres divertidos. " + 
                      "Los niños disfrutarán cada día haciendo manualidades, experimentación, siembra en " +
                      "el huerto, juegos al aire libre, actividades en inglés y descubrirán todas las " +
                      "sorpresas que nos guarda nuestro conejito de Pascua. Lo hemos preparado todo para " + 
                      "que pasen unos días llenos de magia y diversión."
                    ]}
                  />
                  */}
                </div>
              </div>
            </div>						
          </div>
        </div>
      </div>

      <section>											
        <div className="container-fluid padding0">
          <div className="col-xs-6 col-sm-3 padding0">
            <img src="/assets/img/campus/c01.jpg" alt="" /> 
          </div>
          <div className="col-xs-6 col-sm-3 padding0">
            <img src="/assets/img/campus/c02.jpg" alt="" /> 
          </div>
          <div className="col-xs-6 col-sm-3 padding0">
            <img src="/assets/img/campus/c03.jpg" alt="" /> 
          </div>
          <div className="col-xs-6 col-sm-3 padding0">
            <img src="/assets/img/campus/c04.jpg" alt="" /> 
          </div>
        </div>
      </section>

      <FooterContact />
    </>
  );
}

export default Campus;
