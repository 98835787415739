import React from "react";

import Link from "./link";
import { Link as ReactRouterLink } from "react-router-dom";

import { useIntl } from "react-intl";
import { formatMessage } from "../../i18n";

import LanguageSelector from "../language-selector";
import Li from "./Li";
import Menu from "./Menu";

export type Props = {
  location: string;
  languageCode: string;
  setLanguageCode: (languageCode: string) => void;
}

function Header(props: Props) {
  const { languageCode, location, setLanguageCode } = props;
  const intl = useIntl();

  return (
    <header id="header" className="solid-bg-header clear">
      <div className="header-v2">					
        <div className="header-bottom">
          <div className="container">

            <div className="row hidden-xs hidden-sm">
              <div className="col-md-12 text-center">
                <div className="menu-wrap">
                  <nav className="primary-menu">
                    <ul>
                      <Link to="/"><p>{formatMessage(intl, "header.home").toUpperCase()}</p></Link>
                      <Li
                        location={location}
                        locations={["/sobre-happinenss", "/nuestro-rincon"]}
                      >
                        <span>{formatMessage(intl, "header.about").toUpperCase()}</span>
                        <ul className="estut-dropdown">
                            <Link to="/sobre-happinenss">{formatMessage(intl, "header.filosophy").toUpperCase()}</Link>
                            <Link to="/nuestro-rincon">{formatMessage(intl, "header.space").toLocaleUpperCase()}</Link>
                        </ul>
                      </Li>                       
                      <li className="menu-logo-2">
                        <div className="header-logo-2">
                          <ReactRouterLink to="/">
                            <img src="/assets/img/logo.png" alt="main logo" />
                          </ReactRouterLink>
                        </div>
                      </li>
                      <Li
                        location={location}
                        locations={[
                          "/guarderia",
                          "/ludoteca",
                          "/talleres-infantiles",
                          "/escuela-padres",
                          "/campus"
                        ]}
                      >
                        <span>{formatMessage(intl, "header.services").toUpperCase()}</span> 
                        <ul className="estut-dropdown">
                          <Link to="/guarderia">{formatMessage(intl, "header.nursery").toUpperCase()}</Link>
                          <Link to="/ludoteca">{formatMessage(intl, "header.playroom").toUpperCase()}</Link>
                          <Link to="/talleres-infantiles">{formatMessage(intl, "header.children.workshops").toUpperCase()}</Link>
                          <Link to="/escuela-padres">{formatMessage(intl, "header.parent.school").toUpperCase()}</Link>
                          <Link to="/campus">{formatMessage(intl, "header.vacation.campus").toUpperCase()}</Link>
                        </ul>
                      </Li>
                      <Link to="/contacto"><p>{formatMessage(intl, "header.contact").toUpperCase()}</p></Link>
                      <LanguageSelector languageCode="es" setLanguageCode={setLanguageCode} currentLanguageCode={languageCode} />
                      <LanguageSelector languageCode="en" setLanguageCode={setLanguageCode} currentLanguageCode={languageCode} style={{ marginLeft: 10 }} />
                      <LanguageSelector languageCode="ca" setLanguageCode={setLanguageCode} languageCodeStr="cat" currentLanguageCode={languageCode} style={{ marginLeft: 10 }} />
                    </ul>
                  </nav>
                </div>
              </div>
            </div>

            <Menu
              languageCode={languageCode}
              setLanguageCode={setLanguageCode}
            />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;