import React, { useState } from "react";

import { Link as ReactRouterLink } from "react-router-dom";
import Link from "./link";
import LanguageSelector from "../language-selector";
import { formatMessage } from "../../i18n";
import { useIntl } from "react-intl";
import SubMenu from "./SubMenu";

export type Props = {
  languageCode: string;
  setLanguageCode: (languageCode: string) => void;
}

function Menu(props: Props) {
  const { languageCode, setLanguageCode } = props;
  const [ showing, setShowing ] = useState<boolean>(false);
  const [ currentId, setCurrentId ] = useState<number>(0);
  const intl = useIntl();

  const handleOnClick = (ev: React.MouseEvent) => {
    ev.preventDefault();
    setShowing(!showing);
  }
  const handleOnCurrentChange = (id: number) => {
    setCurrentId(id);
  }
  const handleOnSelect = () => {
    setShowing(false);
  }

  return (
    <div className="row hidden-md hidden-lg">
      <div className="col-sm-3">
        <div className="mobile-logo">
          <ReactRouterLink to={"/"} onClick={handleOnSelect}>
            <img src="/assets/img/logo-mobile.png" alt="main logo" />
          </ReactRouterLink>
        </div>
      </div>
      <div className="col-sm-9">
        <div className="mean-container">
          <div className="mean-bar">
            <a
              href="#nav"
              className="meanmenu-reveal"
              onClick={handleOnClick}
            >
              <span></span><span></span><span></span>
            </a>
          </div>
          <nav
            className="mean-nav"
            style={ showing ? undefined : { display: "none" }}
          >
            <ul>
              <Link
                to="/"
                onSelect={handleOnSelect}
              >
                <span>{formatMessage(intl, "header.home").toUpperCase()}</span>
              </Link>
              <SubMenu
                title={formatMessage(intl, "header.about").toUpperCase()}
                id={1}
                currentId={currentId}
                onSelect={handleOnCurrentChange}
                >
                <ul>
                  <Link to="/sobre-happinenss" onSelect={handleOnSelect}>
                    {formatMessage(intl, "header.filosophy").toUpperCase()}
                  </Link>
                  <Link to="/nuestro-rincon" onSelect={handleOnSelect}>
                    {formatMessage(intl, "header.space").toUpperCase()}
                  </Link>
                </ul>
              </SubMenu>
              <SubMenu
                title={formatMessage(intl, "header.services").toUpperCase()}
                id={2}
                currentId={currentId} onSelect={handleOnCurrentChange}
              >
                <ul>
                  <Link to="/guarderia" onSelect={handleOnSelect}>
                    {formatMessage(intl, "header.nursery").toUpperCase()}
                  </Link>
                  <Link to="/ludoteca" onSelect={handleOnSelect}>
                    {formatMessage(intl, "header.playroom").toUpperCase()}
                  </Link>
                  <Link to="/talleres-infantiles" onSelect={handleOnSelect}>
                    {formatMessage(intl, "header.children.workshops").toUpperCase()}
                  </Link>
                  <Link to="/escuela-padres" onSelect={handleOnSelect}>
                    {formatMessage(intl, "header.parent.school").toUpperCase()}
                  </Link>
                  <Link to="/campus" onSelect={handleOnSelect}>
                    {formatMessage(intl, "header.vacation.campus").toUpperCase()}
                  </Link>
                </ul>
              </SubMenu>
              <Link to="/contacto" onSelect={handleOnSelect}>
                {formatMessage(intl, "header.contact").toUpperCase()}
              </Link>
              <LanguageSelector
                languageCode="es"
                setLanguageCode={setLanguageCode}
                currentLanguageCode={languageCode}
                onSelect={handleOnSelect}
              />
              <LanguageSelector
                languageCode="en"
                setLanguageCode={setLanguageCode}
                currentLanguageCode={languageCode}
                onSelect={handleOnSelect}
              />
              <LanguageSelector
                languageCode="ca"
                setLanguageCode={setLanguageCode}
                languageCodeStr="cat" currentLanguageCode={languageCode}
                onSelect={handleOnSelect}
              />
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Menu;
