import React from "react";

function SocialMediaPolicy() {
  return (
    <div id="cookies" className="container">
      <br />
      <br />
      <h1>Política de privacidad redes sociales</h1>
      <br />
      <br />
      <p>
        En cumplimiento de la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de 
        Carácter Personal (LOPD) y la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la 
        Información y de Comercio Electrónico (LSSI-CE),<em>Duet Creix Happinenss,</em> informa a los 
        usuarios, que ha procedido a crear un perfil en las Redes Sociales Facebook, Twitter e Instagram, 
        con la finalidad principal de publicitar sus productos y servicios.
      </p>
      <p>
        Datos de Duet Creix Happinenss:<br />
        Carrer Cuba 7 -  07006, Portitxol<br />
        info@happinenss.com<br />
        www.happinenss.com
      </p>
      <p>
        El usuario dispone de un perfil en la misma Red Social y ha decidido unirse a la página creada 
        por Duet Creix Happinenss, mostrando así interés en la información que se publicite en la Red. Al 
        unirse a nuestra página, nos facilita su consentimiento para el tratamiento de aquellos datos 
        personales publicados en su perfil.
      </p>
      <p>
        El usuario puede acceder en todo momento a las políticas de privacidad de la propia Red Social, 
        así como configurar su perfil para garantizar su privacidad.
      </p>
      <p>
        Duet Creix Happinenss tiene acceso y trata aquella información pública del usuario, en especial, 
        su nombre de contacto. Estos datos, sólo son utilizados dentro de la propia Red Social. No son 
        incorporados a ningún fichero.
      </p>
      <p>
        En relación a los derechos de acceso, rectificación, cancelación y oposición, de los que usted 
        dispone y que pueden ser ejercitados ante <em>Duet Creix Happinenss,</em> de acuerdo con la LOPD, 
        debe tener en cuenta los siguientes matices:
      </p>
    
      <ul>
        <li>
          Acceso: Vendrá definido por la funcionalidad de la Red Social y la capacidad de acceso a
          la información de los perfiles de los usuarios.
        </li>
        <li>
          Rectificación: Sólo podrá satisfacerse en relación a aquella información que se 
          encuentre bajo el control de <em>Duet Creix Happinenss,</em> por ejemplo, eliminar 
          comentarios publicados en la propia página. Normalmente, este derecho deberá ejercerlo 
          ante la Red Social.
        </li>
        <li>
          Cancelación y/u Oposición: Como en el caso anterior, sólo podrá satisfacerse en
          relación a aquella información que se encuentre bajo el control de Duet Creix Happinenss, 
          por ejemplo, dejar de estar unido al perfil.
        </li>
      </ul>
      <br />
      <br />
      <p><em>Duet Creix Happinenss,</em> realizará las siguientes actuaciones:</p>
      <ul>
        <li>Acceso a la información pública del perfil.</li>
        <li>
            Publicación en el perfil del usuario de toda aquella información ya publicada en la
            página de <em>Duet Creix Happinenss,</em>.
        </li>
        <li>Enviar mensajes personales e individuales a través de los canales de la Red Social.</li>
        <li>Actualizaciones del estado de la página que se publicarán en el perfil del usuario.</li>
      </ul>
      <br />
      <p>
        El usuario siempre puede controlar sus conexiones, eliminar los contenidos que dejen de 
        interesarle y restringir con quién comparte sus conexiones, para ello deberá acceder a su 
        configuración de privacidad.
      </p>
      <br />
      <br />    
      <h2>Publicaciones</h2>
      <p>
        El usuario, una vez unido a la página de Duet Creix Happinenss, podrá publicar en ésta última 
        comentarios, enlaces, imágenes o fotografías o cualquier otro tipo de contenido multimedia 
        soportado por la Red Social. El usuario, en todos los casos, debe ser el titular de los mismos, 
        gozar de los derechos de autor y de propiedad intelectual o contar con el consentimiento de los 
        terceros afectados. Se prohíbe expresamente cualquier publicación en la página, ya sean 
        textos, gráficos, fotografías, vídeos, etc. que atenten o sean susceptibles de atentar contra la 
        moral, la ética, el buen gusto o el decoro, y/o que infrinjan, violen o quebranten los derechos de 
        propiedad intelectual o industrial, el derecho a la imagen o la Ley. En estos casos, <em>Duet Creix Happinenss,</em>
        se reserva el derecho a retirar de inmediato el contenido, pudiendo solicitar el bloqueo 
        permanente del usuario.
      </p>
      <p>
        <em>Duet Creix Happinenss,</em> no se hará responsable de los contenidos que libremente ha 
        publicado un usuario.
      </p>
      <p>
        El usuario debe tener presente que sus publicaciones serán conocidas por los otros usuarios, 
        por lo que él mismo es el principal responsable de su privacidad.
      </p>
      <p>
        Las imágenes que puedan publicarse en la página no serán almacenadas en ningún fichero por 
        parte de <em>Duet Creix Happinenss,</em> pero sí que permanecerán en la Red Social.
      </p>
      <br />
      <br />
      <h2>Concursos y promociones</h2>
      <p>
        <em>Duet Creix Happinenss,</em> se reserva el derecho a realizar concursos y promociones, en 
        los que podrá participar el usuario unido a su página. Las bases de cada uno de ellos, cuando 
        se utilice para ello la plataforma de la Red Social, serán publicadas en la misma. Cumpliendo 
        siempre con la LSSI-CE y con cualquier otra norma que le sea de aplicación.
      </p>
      <p>
        La Red Social no patrocina, avala ni administra, de modo alguno, ninguna de nuestras 
        promociones, ni está asociada a ninguna de ellas.
      </p>
      <br />
      <br />
      <h2>Publicidad</h2>
      <p>
        <em>Duet Creix Happinenss,</em> utilizará la Red Social para publicitar sus productos y servicios, 
        en todo caso, si decide tratar sus datos de contacto para realizar acciones directas de prospección 
        comercial, será siempre, cumpliendo con las exigencias legales de la LOPD y de la LSSI-CE.
      </p>
      <p>
        No se considerará publicidad el hecho de recomendar a otros usuarios la página de 
        <em>Duet Creix Happinenss,</em> para que también ellos puedan disfrutar de las promociones o estar 
        informados de su actividad.
      </p>

      <p> A continuación detallamos el enlace a la política de privacidad de la Red Social:</p>
      <ul>
        <li>Facebook: <a href="https://es-es.facebook.com/about/privacy">https://es-es.facebook.com/about/privacy</a></li>
        <li>Twitter: <a href="https://twitter.com/privacy">https://twitter.com/privacy</a></li>
        <li>Instagram: <a href="https://www.instagram.com/about/legal/privacy/?hl=es">https://www.instagram.com/about/legal/privacy/?hl=es</a></li>
      </ul>
      <br />
      <br />
    </div>
  );
}

export default SocialMediaPolicy;
