import React from "react";

import { formatMessage } from "../../i18n";
import { useIntl } from "react-intl";

//import Form from "./Form";

function Contact() {
  const intl = useIntl();

  return (
    <div className="page-content">
      <iframe
        title="location"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3075.858617851762!2d2.6733426908850206!3d39.562795363385106!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x129793bed1416019%3A0x5d4f7dc8fa19f005!2sCarrer+Cuba%2C+7%2C+07006+Palma%2C+Illes+Balears!5e0!3m2!1ses!2ses!4v1479476955920"
        width="100%"
        height={500}
        frameBorder={0}
        style={{ border: 0 }}
        allowFullScreen
      ></iframe>
      
      <div className="contact-us-area padding100">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="area-headding text-center">
                <h2 className="section-title">{formatMessage(intl, "contact.contact.us")}</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="contact-info-list text-center clear">
                <div className="contact-info-inner clear">
                  <div className="single-info location bg-white text-center">
                    <div className="info-icon">
                      <img src="/assets/img/contact/icons/map-marker.png" alt="" />
                      </div>
                      <div className="info-dis">
                        <h3 className="con-in-title">{formatMessage(intl, "contact.address.title")}</h3>
                        <address>
                          <p>Carrer Cuba 7 - 07006, Portitxol</p>
                        </address>
                      </div>
                    </div>
                    <div className="single-info phone-no bg-white  text-center">
                      <div className="info-icon">
                        <img src="/assets/img/contact/icons/phone.png" alt="" />
                      </div>
                      <div className="info-dis">
                        <h3 className="con-in-title">{formatMessage(intl, "contact.phone.title")}</h3>
                        <p><a href="tel:971246327">971 246 327</a></p>
                        </div>
                      </div>
                      <div className="single-info email bg-white  text-center">
                        <div className="info-icon">
                          <img src="/assets/img/contact/icons/email.png" alt="" />
                        </div>
                        <div className="info-dis">
                          <h3 className="con-in-title">{formatMessage(intl, "contact.email.title")}</h3>
                          <p><a href="mailto:info@happinenss.com">info@happinenss.com</a></p>	
                        </div>
                      </div>           
                      <div className="single-info location bg-white text-center">
                        <div className="info-icon">
                          <img src="/assets/img/contact/icons/clock.png" alt="" />
                        </div>
                        <div className="info-dis">
                          <h3 className="con-in-title">{formatMessage(intl, "contact.timetable")}</h3>
                          <address>
                            <p>
                              {formatMessage(intl, "contact.timetable.lines")}
                            </p>
                          </address>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            {/* <Form /> */}
          </div>
      </div>
    </div>
  );
}

export default Contact;
