import _ from "lodash";

import en from "./messages_en";
import es from "./messages_es";
import ca from "./messages_ca";

import ReactIntl, { IntlShape } from "react-intl";
import { PrimitiveType, Options } from "intl-messageformat";

/** Traducciones */
// eslint-disable-next-line import/no-anonymous-default-export
export default { ca, en, es };

export type IntlKey = keyof typeof en | keyof typeof es | keyof typeof ca;

export const formatMessage = (intl: IntlShape, key: IntlKey, values?: Record<string, PrimitiveType> | undefined, opts?: Options) =>
  intl.formatMessage({ id: key }, values, opts);

const FORMATDATE_OPTIONS: ReactIntl.FormatDateOptions = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
};

const FORMATDATETIME_OPTIONS: ReactIntl.FormatDateOptions = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
  hour12: false,
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
};

export const formatDate = (intl: IntlShape, value: string | number | Date) =>
  intl.formatDate(value, FORMATDATE_OPTIONS);

export const formatDateTime = (intl: IntlShape, value: string | number | Date) =>
  intl.formatDate(value, FORMATDATETIME_OPTIONS);

const FORMATPERCENT_OPTIONS: ReactIntl.FormatNumberOptions = {
  maximumFractionDigits: 1,
  minimumFractionDigits: 1,
};

export const formatPercent = (intl: IntlShape, value: number) => `${intl.formatNumber(value, FORMATPERCENT_OPTIONS)}%`;

const FORMATDOUBLE_OPTIONS: ReactIntl.FormatNumberOptions = {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
};

export interface Dictionary {
  [languageCode: string]: { [messageId: string]: string };
}

/**
 * Para cada idioma que se le pasa en languages, recorre los archivos de idioma
 * que se le pasa en messages y mergea sus elementos.
 * Devuelve un objeto con todos los mensajes de los idiomas informados.
 *
 * @param languages array de idiomas (en, es)
 * @param messages array de archivos de idioma (como i18n/messages.json)
 */
export function mergeI18nMessages(languageCodes: string[], messages: Dictionary[]) {
  const localeData: Dictionary = {};
  languageCodes.forEach(languageCode => {
    localeData[languageCode] = {};
    const languageMessages = messages.map(message => message[languageCode]);
    _.merge(localeData[languageCode], ...languageMessages);
  });

  return localeData;
}

export const formatDouble = (intl: IntlShape, value: number) => `${intl.formatNumber(value, FORMATDOUBLE_OPTIONS)}`;

interface NavigatorLanguage {
  userLanguage?: string;
}

/**
 * Devuelve el idioma. Si se le pasa el de la sesión, devuelve ese.
 * En caso contrario, devuelve el idioma del navegador.
 *
 * @param languageCode código de idioma
 */
 export const getLanguage = (languageCode?: string | null) => {
  if (!languageCode) {
    const url = new URL(window.location.href);
    languageCode = url.searchParams.get("lang");
  }

  if (!languageCode) {
    const url = new URL(window.location.href);
    languageCode = url.searchParams.get("lang");
    // Define user's language. Different browsers have the user locale defined
    // on different fields on the `navigator` object, so we make sure to account
    // for these different by checking all of them
    const userLanguage = "userLanguage";
    const language = (navigator.languages && navigator.languages[0]) || navigator.language || (navigator as NavigatorLanguage)[userLanguage];

    // Split locales with a region code
    languageCode = language?.toLowerCase().split(/[_-]+/)[0] || "es";
  }

  return languageCode?.toLowerCase();
};