// eslint-disable-next-line import/no-anonymous-default-export
export default {
  "children.workshops": "Tallers infantils",
  "children.workshops.description": "Millora la comunicació i el vincle amb els petits i al mateix temps ens divertim en família a través de contes, manualitats, jocs tradicionals, etc.",

  "common.home": "Inici",
  
  "contact.address.title": "Contacte",
  "contact.contact.us": "Contacta'ns",
  "contact.email.title": "Email",
  "contact.phone.title": "Telèfon",
  "contact.timetable": "Horari",
  "contact.timetable.lines": "Dilluns a Divendres de 08 a 17h",

  "contact.form.name": "nom i cognom",
  "contact.form.email": "email",
  "contact.form.subject": "assumpte",
  "contact.form.phone": "telèfon",
  "contact.form.message": "missatge",
  "contact.form.send": "enviar missatge",

  "duetsports": "Exercita el teu cos mentre el teu petit està gaudint i aprenent a ",

  "header.about": "sobre Happinenss",
  "header.children.workshops": "tallers infantils",
  "header.contact": "contacte",
  "header.filosophy": "filosofia happinenss",
  "header.home": "inici",
  "header.nursery": "1ª infància",
  "header.parent.school": "escola de pares",
  "header.playroom": "ludotecta",
  "header.services": "serveis",
  "header.space": "el nostre espai",
  "header.vacation.campus": "campus de vacances",

  "filosophy.filosophy": "Filosofia Happinenss",
  "filosophy.what.is.happinenss": "Què és Happinenss?",
  "filosophy.what.is.happinenss.description": `Duet Creix, l'aventura de créixer...

  Obrim les nostres portes obrint també els cors de dues mares i amigues convençudes
  que el ben més preuat d'una societat són els seus nens.
  
  Guarderia Duet Creix Happinenss, neix després de diversos anys de formació i
  experiència i pretén donar resposta a les diferents necessitats personals i familiars
  que es presenten diàriament.
  
  Amb aquest projecte desitgem poder aportar el nostre petit gra de sorra i acompanyar
  als vostres fills en aquesta primera etapa de la seva vida. Els primer anys de criança
  d'un nen marcaran el seu futur de forma significativa. És per això que hem de prestar
  especial atenció a l'entorn que els envolta.
  
  Vivim en una societat en la qual a poc a poc hem perdut la capacitat de convivència,
  i això, ens fa més vulnerables. El grup ens ajuda a desenvolupar certes capacitats i
  valors que ens fan més forts com a individus.
  
  La guarderia és un entorn en el qual el nen rep l’afecte del grup que
  l’envolta, i això farà que creixi més segur i l’ajudarà a enfrontar-se a les seves
  pors. Aprendrà a gestionar millor les seves emocions i a resoldre possibles conflictes
  de forma assertiva.
  `,

  "footer.all.rights.reserved": "Tots els drets reservats | Una idea de",
  "footer.about.us": "Sobre nosaltres",
  "footer.about.us.description": "Guarderia Happinenss és un projecte dedicat a convertir la felicitat en el company perfecte per el creixement del més petits.",
  "footer.contact": "contacte",
  "footer.filosophy": "filosofia happinenss",
  "footer.legal": "Avis legal",
  "footer.space": "el nuestre espai",
  "footer.media.policy": "Política de xarxes socials",

  "footerContact.do.contact": "contactar",
  "footerContact.text": " Deixi'ns un missatge i contactarem amb vostè",

  "footerThreeSquares.creativity": "Fomentem la creativitat en els petits",
  "footerThreeSquares.professionals": "PROFESSIONALS AMB EXPERIÈNCIA I PASSIÓ",
  "footerThreeSquares.space": "UN ESPAI CONDICIONAT PER A NENS",

  "home.about": "SOBRE HAPPINENSS",
  "home.article.1.title": "Som experts en l'educació i cura dels teus fills.",
  "home.article.1.text": "A la nostra escoleta infantil al Portitxol contem amb professionals titulats en educació infantil i amb molta experiència en el sector.",
  "home.article.2.title": "A Happinens eduquem els teus fills treballant l'educació emocional.",
  "home.article.2.text": "A la guarderia infantil volem afavorir el desenvolupament transversal en els més petits, impulsant habilitats i capacitats personals en cada uno. Volem acompanyar als infants en la identificació i expresió de les seves emociones y ajudar-los a reconeixer-les tambén en els demés.",
  "home.article.3.title": "Ajudem a promoure hàbits saludables en els teus fills.",
  "home.article.3.text": "Ajudem als més petits a establir unes rutines per contribuïr a formar uns hàbits. La nostra intenció és promoure hàbits saludables tant en l'alimentació, higiene i salut, como a nivell social, afectiuy psicològic.",
  "home.article.4.title": "A Happinenss creiem en la importància dels valors.",
  "home.article.4.text": "A la nostra escoleta infantil donem molta importància a l'educació en valors com la solidaridat, el respecte y la tolerància. Creiem que és fundamental que els nens creixin interioritzant aquests valors.",
  "home.children.workshops": "tallers infantils",
  "home.children.workshops.description": "Contes, art, diversió y formació, tot un espai ple de'ctividades que fomenten la unió familiar, la formació en equip y la diversió amb els teus.",
  "home.more.info": "Més info",
  "home.parent.school": "escuela de pares",
  "home.parent.school.description": "Espacio formatu destinat a donar resposta a les inquietuts dels pares, comptant amb la colaboración de personal cualificat per les diferents activitats que durem a terme.",
  "home.playroom": "ludoteca",
  "home.playroom.description": "Un espaci que aposta per el suport y la orientació a les famílies en l'apassionant aventura de la criança d'un fill.",
  "home.nursery": "1ª infancia",
  "home.nursery.description": "Guarderia a Palma, un entorn en el que l'infant rep afecte i tendresa del grup que l'envolta, farà que creixi més segur y l'ajudarà a enfrentar-se a les seves pors. Aquest és un lloc ideal per el seu creixement.",
  "home.services": "Serveis",
  "home.services.description": "A la guarderia Happinenss conseguimo la felicitat de cada nen afavorint el seu desenvolupament transversal i impulsant les diferentes habilitats i capacitats personales de cadascun.",
  "home.title": "Happinenss, la guarderia dels teus fills a Palma",
  "home.vacation.campus": "campus de vacances",
  "home.vacation.campus.description": "Tota una experiència dirigida a nens entre 3 i 5 anys per gaudir encara més de les vacances escolares.",
  "home.what.is.happinenss": "Què és Happinenss?",
  "home.what.is.happinenss.description": "Happinenns és la guarderia pels teus fills de entre 4 meses i 3 anys al Portitxol, a Palma. Les famílies ens escullen per que entenem la necessitat que tenen de ser escoltats i de cercar recolçament en la difícil però fascinnt tasca de educar a un fill. Per ajudar a les famílies en la divertida aventura de l'educació dels fills oferim un espai segur y dinàmic en el que els més petits aprendran i es divertiran diàriament.",

  "nursery.feature.1.title": "ambient familiar",
  "nursery.feature.1.description": "Ambient familiar on els peques creixen rodejats de tendresa.",
  "nursery.feature.2.title": "seguridad",
  "nursery.feature.2.description": "Espai segur y dinámico acondicionado para pequeños de cuatro meses a 3 años.",
  "nursery.feature.3.title": "ámplio horario",
  "nursery.feature.3.description": "Ámplio horario: de 8h a 17h.",
  "nursery.feature.4.title": "expressió corporal",
  "nursery.feature.4.description": "La psicomotricitat i l'expressió corporal la traballarem per incentivar la comunicació i comprensió del propi cos.",
  "nursery.feature.5.title": "activitats en anglès",
  "nursery.feature.5.description": "Activitats diàries en anglès.",
  "nursery.feature.6.title": "zona exterior",
  "nursery.feature.6.description": "Zona exterior per gaudir de jocs al aire lliure.",
  "nursery.feature.7.title": "estimulació precoç",
  "nursery.feature.7.description": "La motivació és la nostra metodologia.",
  "nursery.nursery": "1ª infància",
  "nursery.nursery.title": "espaci 1ª infància",
  
  "parents.school": "Escola de pares",
  "parents.school.description": "Alimentació infantil, gestió de les emocions, comunicació possitiva, límits i normes, i tot el que et pugui preocupar.",

  "playroom.playroom": "Ludoteca",
  "playroom.text.1": "El teu petit en les millors mans",
  "playroom.text.2": "Tallers infantils",
  "playroom.text.3": "Jocs de taula i manualitats",
  "playroom.text.4": "Activitats extraescolars",
  "playroom.text.5": "Anglès aprèn jugant",
  "playroom.text.6": "Activitats esportives",

  "playroom.opening.hours": "Horari",
  "playroom.timetable": "De dilluns a divendres de 08h a 17h",

  "space.our.space": "El nostre espai",
  "space.know.our.space": "Coneix el nostre espai",
  "space.know.our.space.description": "Un espai en el qual la cooperació i l'ajuda mútua siguin l'eix de les nostres activitats preparat per prioritzar la seguretat dels més petits amb tècniques i materials especialment pensats per a la seva edat.",

  "vacation.campus": "Campus de vacances",
  "vacation.campus.description": "El millor moment per establir noves relacion i socialitzar mitjançant el joc i la diversió.\n Anglès, activitats esportives, música, jocs d'habilitats, etc. Ens ho passarem molt bé!",
};
