import React from "react";

import { formatMessage } from "../../i18n";
import { useIntl } from "react-intl";
import FooterThreeSquares from "./FooterThreeSquares";
import Breadcrumbs from "../breadcrumbs";

function Filosophy() {
  const intl = useIntl();

  return (
    <div className="page-content">
      <Breadcrumbs
        bgClassname="bg-filosophy"
        pageTitle={formatMessage(intl, "filosophy.filosophy")}
      />
          
      <section className="our-school-area padding-t50">
        <div className="container">
          <div className="row">
            <div className="our-school-inner">
              <div className="col-md-6">
                <div className="school-info padding-t50">
                  <h2 className="section-title">{formatMessage(intl, "filosophy.what.is.happinenss")}</h2>
                  {formatMessage(intl, "filosophy.what.is.happinenss.description").split("\n\n").map((i, index) => <p key={`p${index}`}>{i}</p>)}
                </div>
              </div>
              <div className="col-md-6">
                <div className="school-pic padding-t50">
                  <img src="/assets/img/filosophy/f01.png" alt="about" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FooterThreeSquares />
    </div>
  );
}

export default Filosophy;