import React from "react";

export type Props = {
  id: number;
  title: string;
  currentId: number;
  onSelect: (id: number) => void;
}

function SubMenu(props: React.PropsWithChildren<Props>) {
  const {title, id, currentId, children, onSelect } = props;
  const expanded = id === currentId;
  const handleOnClick = (ev: React.MouseEvent) => {
    ev.preventDefault();
    onSelect(expanded ? 0 : id);
  }
  return (
    <li>
      <span style={{ cursor: "pointer" }} onClick={handleOnClick}>{title}</span>
      <span className="mean-expand">{expanded ? "-" : "+"}</span>'
      {expanded && children}
    </li>
  );
}

export default SubMenu;
