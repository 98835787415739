import React from "react";

import { Link as ReactRouterLink, useLocation } from "react-router-dom";

export type Props = {
  to: string;
  onSelect?: () => void;
}

function Link(props: React.PropsWithChildren<Props>) {
  const { to, children } = props;
  const location = useLocation();
  const handleOnSelect = () => {
    if (props.onSelect) {
      props.onSelect()
    }
  }
  return (
    <li className={location.pathname === to ? "current" : undefined}>
      <ReactRouterLink to={to} onClick={handleOnSelect}>
        {children}
      </ReactRouterLink>
    </li>
  );
}

export default Link;