import React from "react";

export type Props = {
  locations: string[],
  location: string
}

function Li (props: React.PropsWithChildren<Props>) {
  const { location, locations, children } = props;
  return (
    <li className={locations.find((i) => i === location) != null ? "current" : undefined}>
      {children}
    </li>
  );
}

export default Li;
