import React from "react";

import { useIntl } from "react-intl";
import { formatMessage } from "../../i18n";
import Square from "./Square";

function FooterThreeSquares() {
  const intl = useIntl();

  return (
    <section className="service-v3-area">
      <div className="service-list clear">
        <Square
          img1Src="/assets/img/filosophy/s01.jpg"
          img1Alt="servicio guardería Palma"
          img2Src="/assets/img/filosophy/icons/s01.png"
          img2Alt="guardería Palma"
          text={formatMessage(intl, "footerThreeSquares.creativity")}
        />
        <Square
          img1Src="/assets/img/filosophy/s02.jpg"
          img1Alt="servicio guardería Palma"
          img2Src="/assets/img/filosophy/icons/s02.png"
          img2Alt="guardería Palma"
          text={formatMessage(intl, "footerThreeSquares.professionals")}
        />
        <Square
          img1Src="/assets/img/filosophy/s03.jpg"
          img1Alt="servicio guardería Palma"
          img2Src="/assets/img/filosophy/icons/s03.png"
          img2Alt="guardería Palma"
          text={formatMessage(intl, "footerThreeSquares.space")}
        />
      </div>
    </section>
  );
}

export default FooterThreeSquares;
