import React from "react";

import { Link } from "react-router-dom";
import { formatMessage } from "../../i18n";
import { useIntl } from "react-intl";

import Article from "./Article";
import DuetSports from "./DuetSports";
import Service from "./Service";
import NivoSlider from "./NivoSlider";

function Home() {
  const intl = useIntl();
  return (
    <>
      <NivoSlider
        imageSrc="/assets/img/home/h01.jpg"
        text={formatMessage(intl, "home.title")}
      />  
      <section className="service-area service-v2 padding100">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="area-headding text-center">
                <h2 className="section-title">{formatMessage(intl, "home.services")}</h2>
                <p className="section-dis font17">{formatMessage(intl, "home.services.description")}</p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="service-list">
              <Service
                backgroundColor="#6fc8cd"
                imgAlt="service icon guardería Palma"
                imgSrc="/assets/img/home/icons/s01.png"
                title={formatMessage(intl, "home.nursery").toUpperCase()}
                description={formatMessage(intl, "home.nursery.description")}
                link="/guarderia"
              />

              <Service
                backgroundColor="#97ca8b"
                imgAlt="guardería Mallorca"
                imgSrc="/assets/img/home/icons/s02.png"
                title={formatMessage(intl, "home.playroom").toUpperCase()}
                description={formatMessage(intl, "home.playroom.description")}
                link="/ludoteca"
              />

              <Service
                backgroundColor="#f490b9"
                imgAlt="guardería Palma"
                imgSrc="/assets/img/home/icons/s03.png"
                title={formatMessage(intl, "home.children.workshops").toUpperCase()}
                description={formatMessage(intl, "home.children.workshops.description")}
                link="/talleres-infantiles"
              />

              <Service
                backgroundColor="#fbb04b"
                imgAlt="guardería Palma"
                imgSrc="/assets/img/home/icons/s04.png"
                title={formatMessage(intl, "home.parent.school").toUpperCase()}
                description={formatMessage(intl, "home.parent.school.description")}
                link="/escuela-padres"
              />

              <Service
                backgroundColor="#bfa37c"
                imgAlt="guardería Palma"
                imgSrc="/assets/img/home/icons/s05.png"
                title={formatMessage(intl, "home.vacation.campus").toUpperCase()}
                description={formatMessage(intl, "home.vacation.campus.description")}
                link="/campus"
              /> 
            </div>
          </div>
        </div>
      </section>

      <section className="search-area bg-color-2 fix ">
        <div className="search-lside one-half">
          <img src="/assets/img/home/h02.jpg" alt="guardería Palma de Mallorca" />
        </div>
        <div className="search-rside one-half padding85 padding-left65 paddingmob">
          <div className="area-headding color-white text-left">
            <h2 className="section-title">{formatMessage(intl, "home.what.is.happinenss")}</h2>
            <p style={{ fontSize: 15 }}>{formatMessage(intl, "home.what.is.happinenss.description")}</p>
            <Link to="/sobre-happinenss" className="estut-btn btn-nosotros">
              {formatMessage(intl, "home.about").toUpperCase()}
            </Link>
          </div>			
        </div>
      </section>

      <section className="latest-blog-area-v2 top100">
      </section>
    
      <section className="container margenb60">
        <Article
          color="#6fc8cd"
          title={formatMessage(intl, "home.article.1.title")}
          text={formatMessage(intl, "home.article.1.text")}
        />
        <Article
          color="#97ca8b"
          title={formatMessage(intl, "home.article.2.title")}
          text={formatMessage(intl, "home.article.2.text")}
        />
        <Article
          color="#f490b9"
          title={formatMessage(intl, "home.article.3.title")}
          text={formatMessage(intl, "home.article.3.text")}
        />      
        <Article
          color="#fbb04b"
          title={formatMessage(intl, "home.article.4.title")}
          text={formatMessage(intl, "home.article.4.text")}
        />
      </section>

      <DuetSports />
    </>
  );
}

export default Home;
