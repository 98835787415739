import React from "react";

import Breadcrumbs from "../breadcrumbs";
import FooterContact from "../footer-contact";

import { formatMessage } from "../../i18n";
import { useIntl } from "react-intl";

function ParentsSchool() {
  const intl = useIntl();
  return (
    <div className="page-content">
      <Breadcrumbs
        bgClassname="bg-parents-school"
        pageTitle={formatMessage(intl, "parents.school")}
      />

      <div className="col-xs-12 padding-t50 padding-b50 text-center">
        <div className="container">
          <h1 className="font35" style={{ color: "#fbb04b" }}>{formatMessage(intl, "parents.school")}</h1>
          <p className="font17" style={{ marginTop: 20 }}>{formatMessage(intl, "parents.school.description")}</p>
        </div>
      </div>

      <div className="latest-blog-area blog-page padding50">
        <div className="container">
          <div className="row">
            <div className="blog-post-inner blog-small-image">
            </div>
          </div>	
        </div>
      </div>
      <FooterContact />
    </div>
  );
}

export default ParentsSchool;
