import React from "react";

import Breadcrumbs from "../breadcrumbs";
import FooterContact from "../footer-contact";

import { formatMessage } from "../../i18n";
import { useIntl } from "react-intl";

import Feature from "./Feature";

function Nursery() {
  const intl = useIntl();

  return (
    <div className="page-content">
      <Breadcrumbs
        bgClassname="bg-nursery"
        pageTitle={formatMessage(intl, "nursery.nursery")}
      />
      
      <section id="features" className="section-content features-area padding-t100">
        <div className="container">
          
          <div className="row">
            <div className="col-md-12">
              <div className="section-title-area text-center">
                <h2 className="section-title">{formatMessage(intl, "nursery.nursery.title").toUpperCase()}</h2>
              </div>
            </div>
          </div>

          <div className="row padding-t50">
            <div className="feature-list">

              <div className="col-xs-12">
                <Feature
                  imgSrc="/assets/img/nursery/icons/n06.png"
                  title={formatMessage(intl, "nursery.feature.1.title").toUpperCase()}
                  description={formatMessage(intl, "nursery.feature.1.description")}
                />
                <Feature
                  imgSrc="/assets/img/nursery/icons/n03.png"
                  title={formatMessage(intl, "nursery.feature.2.title").toUpperCase()}
                  description={formatMessage(intl, "nursery.feature.2.description")}
                />
                <Feature
                  imgSrc="/assets/img/nursery/icons/n02.png"
                  title={formatMessage(intl, "nursery.feature.3.title").toUpperCase()}
                  description={formatMessage(intl, "nursery.feature.3.description")}
                />
              </div>

              <div className="col-xs-12">
                <Feature
                  imgSrc="/assets/img/nursery/icons/n04.png"
                  title={formatMessage(intl, "nursery.feature.4.title").toUpperCase()}
                  description={formatMessage(intl, "nursery.feature.4.description")}
                />
                <Feature
                  imgSrc="/assets/img/nursery/icons/n05.png"
                  title={formatMessage(intl, "nursery.feature.5.title").toUpperCase()}
                  description={formatMessage(intl, "nursery.feature.5.description")}
                />
                <Feature
                  imgSrc="/assets/img/nursery/icons/n01.png"
                  title={formatMessage(intl, "nursery.feature.6.title").toUpperCase()}
                  description={formatMessage(intl, "nursery.feature.6.description")}
                />
              </div>

              <div className="col-xs-12">
                <Feature
                  imgSrc="/assets/img/nursery/icons/n07.png"
                  title={formatMessage(intl, "nursery.feature.7.title").toUpperCase()}
                  description={formatMessage(intl, "nursery.feature.7.description")}
                />
              </div>

            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid padding0">			  											
          <div className="col-xs-6 col-sm-3 padding0">
            <img src="/assets/img/nursery/n01.jpg" alt="" /> 
          </div>
          <div className="col-xs-6 col-sm-3 padding0">
            <img src="/assets/img/nursery/n02.jpg" alt=""/> 
          </div>
          <div className="col-xs-6 col-sm-3 padding0">
            <img src="/assets/img/nursery/n03.jpg" alt=""/> 
          </div>
          <div className="col-xs-6 col-sm-3 padding0">
            <img src="/assets/img/nursery/n04.jpg" alt=""/> 
          </div>
        </div>
      </section>
      <FooterContact />
    </div>
  );
}

export default Nursery;