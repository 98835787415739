import React from "react";

import { formatMessage } from "../../i18n"
import { useIntl } from "react-intl";
import Breadcrumbs from "../breadcrumbs";

function Playroom() {
  const intl = useIntl();

  return (
    <div className="page-content">
      <Breadcrumbs
        bgClassname="bg-playroom"
        pageTitle={formatMessage(intl, "playroom.playroom")}
      />

      <div className="latest-blog-area blog-page padding-t50">
        <div className="container">
          <div className="row">
            <div className="blog-post-inner blog-small-image">
              <div className="col-md-12 padding-t50">
                <div className="col-xs-12 col-sm-6">
                  <img style={{ border: "15px solid rgba(221, 192, 27, 0.25)", padding: 10 }} alt="blog img" src="/assets/img/playroom/p00.jpg" />
                </div>
                <div className="col-xs-12 col-sm-6 text-left padding30 p17" style={{ paddingTop: 10 }}>
                  <h1 className="titulo-ludo">{formatMessage(intl, "playroom.playroom")}</h1>
                  <p><i className="fa fa-check" aria-hidden="true" style={{ color: "#75c6cc" }}></i>{formatMessage(intl, "playroom.text.1")}</p>
                  <p><i className="fa fa-check" aria-hidden="true" style={{ color: "#a1cc8e" }}></i>{formatMessage(intl, "playroom.text.2")}</p>
                  <p><i className="fa fa-check" aria-hidden="true" style={{ color: "#e27817" }}></i>{formatMessage(intl, "playroom.text.3")}</p>
                  <p><i className="fa fa-check" aria-hidden="true" style={{ color: "#ddc01b" }}></i>{formatMessage(intl, "playroom.text.4")}</p>
                  <p><i className="fa fa-check" aria-hidden="true" style={{ color: "#f490b9" }}></i>{formatMessage(intl, "playroom.text.5")}</p>
                  <p><i className="fa fa-check" aria-hidden="true" style={{ color: "#bfa37c" }}></i>{formatMessage(intl, "playroom.text.6")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section>
          <div className="container-fluid padding0">
            <div className="col-xs-6 col-sm-3 padding0">
              <img src="/assets/img/playroom/p01.jpg" alt=""/> 
            </div>
            <div className="col-xs-6 col-sm-3 padding0">
              <img src="/assets/img/playroom/p02.jpg" alt=""/> 
            </div>
            <div className="col-xs-6 col-sm-3 padding0">
              <img src="/assets/img/playroom/p03.jpg" alt=""/> 
            </div>
            <div className="col-xs-6 col-sm-3 padding0">
              <img src="/assets/img/playroom/p04.jpg" alt=""/> 
            </div>
          </div>							
        </section>
        <div className="container-fluid padding-t50" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <section className="team-area v1 bg-1 overlay-bg padding100">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <div className="team-wrap fix">
                    <div className="area-headding color-white text-center">
                      <img src="/assets/img/playroom/icons/p01.png" alt=""/>
                      <h2 className="section-title padding-t30">{formatMessage(intl, "playroom.opening.hours")}</h2>
                      <p style={{ fontSize: 22 }}>{formatMessage(intl, "playroom.timetable")}</p>
                    </div>	
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Playroom;
