import React, { useEffect } from "react";

import { formatMessage } from "../../i18n";
import { useIntl } from "react-intl";

import FooterContact from "../footer-contact";
import Breadcrumbs from "../breadcrumbs";

const images = ["sp1.jpg", "sp2.jpg", "sp3.jpg", "sp4.jpg", "sp5.jpg", "sp6.jpg", "sp7.jpg", "sp8.jpg", "sp9.jpg", "sp10.jpg", "sp11.jpg", "sp12.jpg"];

function Space() {
  const intl = useIntl();

  useEffect(() => {
    const oldScript = document.getElementById("spaceScript");
    if (oldScript) {
      document.body.removeChild(oldScript);
    }
    const script = document.createElement("script");
    script.async = true;
    script.id="spaceScript"
    script.innerHTML = `
      $(document).ready(function() {
        $('.popup-gallery').magnificPopup({
          delegate: 'a',
          type: 'image',
          tLoading: 'Cargando imagen #%curr%...',
          mainClass: 'mfp-img-mobile',
          gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0,1] // Will preload 0 - before current, and 1 after the current image
          },
          image: {
            tError: '<a href="%url%">La imagen #%curr%</a> no pudo cargarse.',
          }
        });
      });  
    `;
    document.body.appendChild(script);
    }, []);

  return (
    <div className="page-content">
      <Breadcrumbs
        bgClassname="bg-space"
        pageTitle={formatMessage(intl, "space.our.space")}
      />

      <section className="our-team-area-v3 padding-t100 padding-b100">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="area-headding text-center">
                <h2 className="section-title">{formatMessage(intl, "space.know.our.space")}</h2>
                <p>{formatMessage(intl, "space.know.our.space.description")}</p>
              </div>
            </div>
          </div>
        </div>
          
        <div className="container popup-gallery clear">
          {images.map((i, index) => (
            <div key={`img${index}`} className="single-member">
              <div className="hover ehover1">
                <a href={`/assets/img/space/${i}`} className="popup-gallery">
                  <img src={`/assets/img/space/${i}`} alt=""/>
                </a>			
              </div>
            </div>	
          ))}
        </div>
      </section>

      <FooterContact />
    </div>
  );
}

export default Space;
