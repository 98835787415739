import React from "react";

import { Link } from "react-router-dom";
import { formatMessage } from "../../i18n";
import { useIntl } from "react-intl";

export type Props = {
  bgClassname: string;
  pageTitle: string;
}

function Breadcrumbs(props: Props) {
  const { bgClassname, pageTitle } = props;
  const intl = useIntl();
  return (
    <div className={`page-header-v1 ${bgClassname} overlay-bg  text-center`}>
      <div className="page-header-inner">
        <h2 className="page-title">{pageTitle}</h2>
        <div className="breadcrumbs">
          <Link to="/">{formatMessage(intl, "common.home")}</Link>
          <span className="separator">/</span>
          <span>{pageTitle}</span>
        </div>
      </div>
    </div>
  );
}

export default Breadcrumbs;
