import React, { useState } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";

import { Dictionary, getLanguage } from "../i18n";

import { IntlProvider } from "react-intl";

import Footer from "./footer";
import Header from "./header";
import Home from "./home";
import Filosophy from './filosophy';
import Space from "./space";
import Contact from './contact';
import Nursery from './nursery';
import Playroom from './playroom';
import ChildrenWorkshops from './children-workshops';
import ParentsSchool from './parents-school';
import Campus from './campus';
import SocialMediaPolicy from './social-media-policy';
import LegalNotice from './legal-notice';

type Props = {
  messages: Dictionary;
}

function DefaultRouteResolver() {
  const location = useLocation();
  let to = "/";
  const pathname = location.pathname;
  if (pathname.endsWith(".php")) {
    to = location.pathname.substring(0, pathname.indexOf(".php"));
  }
  return <Navigate to={to}/>;
}

function App(props: Props) {
  const { messages } = props;
  const [languageCode, setLanguageCode] = useState(getLanguage() || "es");
  
  const getMessages = (languageCode: string) => {
    return messages ? messages[languageCode] || messages.es : undefined;
  };

  return (
    <IntlProvider locale={languageCode} messages={getMessages(languageCode)}>
      <BrowserRouter >
        <div className="spectar"></div>
        <Header languageCode={languageCode} setLanguageCode={setLanguageCode} />
        <Routes >
          <Route path="/" element={<Home />} />
          <Route path="/sobre-happinenss" element={<Filosophy />} />
          <Route path="/nuestro-rincon" element={<Space />} />
          <Route path="/contacto" element={<Contact />} />
          <Route path="/guarderia" element={<Nursery />} />
          <Route path="/ludoteca" element={<Playroom />} />
          <Route path="/talleres-infantiles" element={<ChildrenWorkshops />} />
          <Route path="/escuela-padres" element={<ParentsSchool />} />
          <Route path="/campus" element={<Campus />} />
          <Route path="/redes" element={<SocialMediaPolicy />} />
          <Route path="/uso" element={<LegalNotice />} />
          <Route path="*" element={<DefaultRouteResolver />} />
        </Routes>
        <Footer />
      </BrowserRouter>
      <div className="spectar"></div>
    </IntlProvider>
  );
}

export default App;
