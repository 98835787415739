import React from "react";

import { formatMessage } from "../../i18n";
import { useIntl } from "react-intl";
import { useEffect } from "react";
import { Link } from "react-router-dom";

function Footer() {
  const intl = useIntl();

  useEffect(() => {
    const oldScript = document.getElementById("footerScript");
    if (oldScript) {
      document.body.removeChild(oldScript);
    }
    const script = document.createElement("script");
    script.async = true;
    script.id="footerScript"
    script.innerHTML = `
      $(".blog-flickr").sliphover();
      $.scrollUp({
        scrollText: '<i class="fa fa-angle-up"></i>',
        easingType: 'linear',
        scrollSpeed: 900,
        animation: 'fade'
    });
    `;
    document.body.appendChild(script);
  }, []);

  return (
    <footer id="footer-area">
      <div className="footer-top-area bg-1 overlay-bg padding70">
        <div className="container">
          
          <div className="row">
            <div className="newsletter">
              <div className="col-md-6 col-md-offset-3 text-center">
                <div className="footer-logo">
                  <img src="/assets/img/footer/logo.png" alt="footer Logo" />
                </div>    
              </div>
            </div>
          </div>
          
          <div className="row">
            <div className="col-md-3 col-sm-4">
              <div className="footer-top-menu">
                <span style={{ fontFamily: "'Inika', serif", marginBottom: 10 }} className="widget-title">
                  {formatMessage(intl, "footer.about.us").toUpperCase()}
                </span>
                <div className="fo-aboutus-container">
                  <p style={{ marginBottom: 10 }}>
                    {formatMessage(intl, "footer.about.us.description")}
                  </p>
                  <a href="https://portitxol.duetsports.com/" target="_blank" rel="noreferrer">
                    <img src="/assets/img/footer/duet-footer.png" alt="Duet Sports logo" />
                  </a>
                </div>
              </div>
            </div>
            
            <div className="col-md-3 col-sm-4">
              <div className="footer-top-menu">
                <span style={{ fontFamily: "'Inika', serif", marginBottom: 10 }} className="widget-title">{formatMessage(intl, "footer.contact").toUpperCase()}</span>
                <address>
                  <p><i className="fa fa-map-marker "></i>&nbsp; Carrer Cuba 7 | 07006, Portitxol</p>
                  <p><i className="fa fa-envelope" style={{ fontSize: 11 }}></i>&nbsp; <a href="mailto:info@happinenss.com "> info@happinenss.com </a></p>
                  <p><i className="fa fa-phone"></i>&nbsp; <a href="tel:971246327">971 246 327</a></p>
                </address>
                <div className="footer-social-bookmark">
                  <ul>
                    <li><a target="_blank" rel="noreferrer" href="https://www.facebook.com/Duet-Creix-Happinenss-1783979808543040/?fref=ts" className="facebook"><i className="fa fa-facebook-square"></i></a></li>
                    <li><a target="_blank" rel="noreferrer" href="https://twitter.com/Happinenss" className="twitter"><i className="fa fa-twitter-square"></i></a></li>
                    <li><a target="_blank" rel="noreferrer" href="https://plus.google.com/u/0/111842136588193760715" className="google-plus"><i className="fa fa-google-plus-square"></i></a></li>
                    <li><a target="_blank" rel="noreferrer" href="https://www.instagram.com/duetcreixhappinenss/" className="instagram"><i style={{ color: "#517fa6" }} className="fa fa-instagram"></i></a></li>
                  </ul>
                </div>
              </div>
            </div>
            
            <div className="col-md-3 hidden-sm">
              <div className="footer-top-menu">
                <span style={{ fontFamily: "'Inika', serif", marginBottom: 10 }} className="widget-title">Links</span>
                <div className="fo-course-container">
                  <ul style={{ lineHeight: "25px" }}>
                    <li><Link className="font400" to="/sobre-happinenss">{formatMessage(intl, "footer.filosophy").toUpperCase()}</Link></li>
                    <li><Link className="font400" to="/nuestro-rincon">{formatMessage(intl, "footer.space").toUpperCase()}</Link></li>
                    <li><Link className="font400" to="/contacto">{formatMessage(intl, "footer.contact").toUpperCase()}</Link></li>
                    <li><Link className="font400" to="/uso">{formatMessage(intl, "footer.legal")}</Link></li>
                    <li><Link className="font400" to="/redes">{formatMessage(intl, "footer.media.policy")}</Link></li>
                  </ul>
                </div>   
              </div>
            </div>
            
            <div className="col-md-3 col-sm-4">
              <div className="footer-top-menu">
                <span style={{ fontFamily: "'Inika', serif", marginBottom: 10 }} className="widget-title">{formatMessage(intl, "footer.space").toUpperCase()}</span>
                <div className="blog-flickr">
                  <ul>
                    <li><a href="nuestro-rincon"><img src="/assets/img/footer/f01.jpg" alt="" /></a></li>
                    <li><a href="nuestro-rincon"><img src="/assets/img/footer/f02.jpg" alt="" /></a></li>
                    <li><a href="nuestro-rincon"><img src="/assets/img/footer/f03.jpg" alt="" /></a></li>
                    <li><a href="nuestro-rincon"><img src="/assets/img/footer/f04.jpg" alt="" /></a></li>
                    <li><a href="nuestro-rincon"><img src="/assets/img/footer/f05.jpg" alt="" /></a></li>
                    <li><a href="nuestro-rincon"><img src="/assets/img/footer/f06.jpg" alt="" /></a></li>
                  </ul>
                </div>
              </div>
            </div>
          
          </div>
        </div>
      </div>

      <div className="footer-bottom-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="copyright-wrap text-center">
                <p>&copy; 2016 <span style={{ color: "#fbb04b" }} className="inika">Happinenss.</span> {formatMessage(intl, "footer.all.rights.reserved")} <a style={{ color: "#999" }} href="http://www.mklab.es" target="_blank" rel="noreferrer"><strong>Mklab</strong></a>.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
