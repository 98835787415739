import React from "react";

export type Props = {
  title: string;
  color: string;
  text: string;
}

function Article(props: Props) {
  const { title, color, text } = props;
  return (
    <div className="col-xs-12 col-sm-6 col-md-3">
      <h3 style={{ color }}>{title}</h3>
      <p>{text}</p>
    </div>
  );
}

export default Article;
