import React from "react";

import { useIntl } from "react-intl";
import FooterContact from "../footer-contact";
import { formatMessage } from "../../i18n";
import Breadcrumbs from "../breadcrumbs";

// import BlogEntry from "../blog-entry";

function ChildrenWorkshops() {
  const intl = useIntl();

  return (
    <>
      <div className="page-content">
        <Breadcrumbs
          bgClassname="bg-children-workshops"
          pageTitle={formatMessage(intl, "children.workshops")}
        />

        <div className="col-xs-12 padding-t50 padding-b50 text-center">
          <div className="container">
            <h1 className="font35" style={{ color: "#f490b9" }}>{formatMessage(intl, "children.workshops")}</h1>
            <p className="font17" style={{ marginTop: 20 }}>{formatMessage(intl, "children.workshops.description")}</p>
          </div>
        </div>

        <div className="latest-blog-area blog-page padding50">
          <div className="container">
            <div className="row">
              <div className="blog-post-inner blog-small-image">
                <div className="col-md-12 col-xs-12">
                  {/*
                  <BlogEntry
                    imageAlt="blog img"
                    imageSrc="/assets/proyectos/duetweb.jpg"
                    title="TALLER DE MANUALIDADES NAVIDEÑAS"
                    date="03/12/2016"
                    text={[
                      "¡Ven a pasar un rato divertido con tu peque y cread un adorno navideño totalmente vuestro!",
                      "Con actividades de este tipo, los más pequeños tomarán conciencia de todo lo que pueden hacer con sus propias manos... además de pasar momentos divertidos en familia.",
                      "Precio: 5€ por niño. Descuento para hermanos."
                    ]} />
                  */}
                </div>
              </div>
            </div>		
          </div>
        </div>
      </div>
      <section>
        <div className="container-fluid padding0">
          <div className="col-xs-6 col-sm-3 padding0">
            <img src="/assets/img/children-workshops/w01.jpg" alt="" /> 
          </div>
          <div className="col-xs-6 col-sm-3 padding0">
            <img src="/assets/img/children-workshops/w02.jpg" alt="" /> 
          </div>
          <div className="col-xs-6 col-sm-3 padding0">
            <img src="/assets/img/children-workshops/w03.png" alt="" /> 
          </div>
          <div className="col-xs-6 col-sm-3 padding0">
            <img src="/assets/img/children-workshops/w04.jpg" alt=""/> 
          </div>
        </div>
      </section>
      <FooterContact />
    </>
  );
}

export default ChildrenWorkshops;
