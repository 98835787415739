import React from "react";

import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { formatMessage } from "../../i18n";

function FooterContact() {
  const intl = useIntl();

  return (
    <div className="see-all-photo">
      <div className="container">
        <div className="row">
          <div className="col-md-9 col-lg-9 col-sm-8 col-xs-12 text-left">
            <h2 className="white">{formatMessage(intl, "footerContact.text")}</h2>
          </div>
          <div className="col-md-3 col-lg-3 col-sm-4 col-xs-12 text-right mt-10">
            <Link to="/contacto" className="estut-btn-contacto default-color">{formatMessage(intl, "footerContact.do.contact")}</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterContact;
