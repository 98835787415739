import React from "react";

import { formatMessage } from "../../i18n";
import { useIntl } from "react-intl";

function DuetSports() {
  const intl = useIntl();
  return (
    <a href="https://portitxol.duetsports.com/" target="_blank" rel="noreferrer">
      <section className="call-to-action bg-1 overlay-bg padding30">
          <div className="container">
              <div className="row">
                  <div className="col-md-12 text-center">
                      <div className="call-to-action-wrap">
                          <h5 className="call-content col-sm-3 col-xs-12"><img src="/assets/img/duet.png" alt="guardería Palma"/></h5>
                          <span className="call-title col-sm-9 col-xs-12 text-left padding-t30" style={{ fontFamily: "lato, sans-serif" }}>
                            {formatMessage(intl, "duetsports")}<strong>HAPPINENSS</strong>
                          </span>							
                      </div>
                  </div>
              </div>
          </div>
      </section>
    </a>    
  );
}

export default DuetSports;
