import React from "react";

function LegalNotice() {
  return (
    <section className="page-content">
      <div id="cookies" className="container">
        <br />
        <br />
          <h1>Condiciones de uso</h1><br />
          <p>En cumplimiento de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico (LSSI-CE), <em>Duet Creix Happinenss,</em> informa que es titular del sitio web <em>www.happinenss.com</em>. De acuerdo con la exigencia del artículo 10 de la citada Ley, <em>Duet Creix Happinenss,</em> informa de los siguientes datos:</p>
          <p>El titular de este sitio web es Duet Creix Happinenss, con domicilio social en Carrer Cuba 7 - 07006, Portitxol, inscrita en el Registro Mercantil. La dirección de correo electrónico de contacto con la empresa es: info@happinenss.com</p>
          <br />
          <br />

          <h3>Usuario y régimen de responsabilidades</h3><br />
          <p>La navegación, acceso y uso por el sitio web de <em>Duet Creix Happinenss,</em> confiere la condición de usuario, por la que se aceptan, desde la navegación por el sitio web de <em>Duet Creix Happinenss,</em>, todas las condiciones de uso aquí establecidas sin perjuicio de la aplicación de la correspondiente normativa de obligado cumplimiento legal según el caso.</p>
          <p>El sitio web de <em>Duet Creix Happinenss,</em> proporciona gran diversidad de información, servicios y datos. El usuario asume su responsabilidad en el uso correcto del sitio web. Esta responsabilidad se extenderá a:</p>
          <ul>
            <li>La veracidad y licitud de las informaciones aportadas por el usuario en los formularios extendidos por <em>Duet Creix Happinenss,</em> para el acceso a ciertos contenidos o servicios ofrecidos por el web.</li>
            <li>El uso de la información, servicios y datos ofrecidos por <em>Duet Creix Happinenss,</em> contrariamente a lo dispuesto por las presentes condiciones, la Ley, la moral, las buenas costumbres o el orden público, o que de cualquier otro modo puedan suponer lesión de los derechos de terceros o del mismo funcionamiento del sitio web.</li>
          </ul>
          <br />
          <br />

          <h3>Política de enlaces y exenciones de responsabilidad</h3><br />
          <p><em>Duet Creix Happinenss,</em> no se hace responsable del contenido de los sitios web a los que el usuario pueda acceder a través de los enlaces establecidos en su sitio web y declara que en ningún caso procederá a examinar o ejercitar ningún tipo de control sobre el contenido de otros sitios de la red. Asimismo, tampoco garantizará la disponibilidad técnica, exactitud, veracidad, validez o legalidad de sitios ajenos a su propiedad a los que se pueda acceder por medio de los enlaces.</p>
          <p><em>Duet Creix Happinenss,</em> declara haber adoptado todas las medidas necesarias para evitar cualquier daño a los usuarios de su sitio web, que pudieran derivarse de la navegación por su sitio web. En consecuencia, <em>Duet Creix Happinenss,</em> no se hace responsable, en ningún caso, de los eventuales daños que por la navegación por Internet pudiera sufrir el usuario.</p>
          <br />
          <br />

          <h3>Modificaciones</h3><br />
          <p><em>Duet Creix Happinenss,</em> se reserva el derecho a realizar las modificaciones que considere oportunas, sin aviso previo, en el contenido de su sitio web. Tanto en lo referente a los contenidos del sitio web, como en las condiciones de uso del mismo. Dichas modificaciones podrán realizarse a través de su sitio web por cualquier forma admisible en derecho y serán de obligado cumplimiento durante el tiempo en que se encuentren publicadas en la web y hasta que no sean modificadas válidamente por otras posteriores.</p>
          <br />
          <br />

          <h3>Protección de datos</h3><br />
          <p>De conformidad con lo que establece la Ley Orgánica 15/1999 de Protección de Datos de Carácter Personal (LOPD), <em>Duet Creix Happinenss,</em> informa a los usuarios de su sitio web que los datos personales recabados por la empresa, mediante los formularios sitos en sus páginas, serán introducidos en un fichero automatizado bajo la responsabilidad de <em>Duet Creix Happinenss,</em>, con la finalidad de poder facilitar, agilizar y cumplir los compromisos establecidos entre ambas partes.</p>
          <p>Asimismo, <em>Duet Creix Happinenss,</em> informa de la posibilidad de ejercer los derechos de acceso, rectificación, cancelación y oposición mediante un escrito a la dirección:  Carrer Cuba 7 - 07006, Portitxol</p>
          <p>Mientras el usuario no comunique lo contrario a <em>Duet Creix Happinenss,</em>, ésta entenderá que sus datos no han sido modificados, que el usuario se compromete a notificar a <em>Duet Creix Happinenss,</em> cualquier variación y que <em>Duet Creix Happinenss,</em> tiene el consentimiento para utilizarlos a fin de poder fidelizar la relación entre las partes.</p>
          <br />
          <br />

          <h3>Propiedad intelectual e industrial</h3><br />
          <p><em>Duet Creix Happinenss,</em> por sí misma o como cesionaria, es titular de todos los derechos de propiedad intelectual e industrial de su página web, así como de los elementos contenidos en la misma (a título enunciativo, imágenes, sonido, audio, vídeo, software o textos; marcas o logotipos, combinaciones de colores, estructura y diseño, selección de materiales usados, programas de ordenador necesarios para su funcionamiento, acceso y uso, etc.), titularidad de <em>Duet Creix Happinenss,</em>. Serán, por consiguiente, obras protegidas como propiedad intelectual por el ordenamiento jurídico español, siéndoles aplicables tanto la normativa española y comunitaria en este campo, como los tratados internacionales relativos a la materia y suscritos por España.</p>
          <p>Todos los derechos reservados. En virtud de lo dispuesto en los artículos 8 y 32.1, párrafo segundo, de la Ley de Propiedad Intelectual, quedan expresamente prohibidas la reproduccioón, la distribución y la comunicación pública, incluida su modalidad de puesta a disposición, de la totalidad o parte de los contenidos de esta página web, con fines comerciales, en cualquier soporte y por cualquier medio técnico, sin la autorización de <em>Duet Creix Happinenss,</em>.</p>
          <p>El usuario se compromete a respetar los derechos de Propiedad Intelectual e Industrial titularidad de <em>Duet Creix Happinenss,</em>. Podrá visualizar los elementos del portal e incluso imprimirlos, copiarlos y almacenarlos en el disco duro de su ordenador o en cualquier otro soporte físico siempre y cuando sea, única y exclusivamente, para su uso personal y privado. El usuario deberá abstenerse de suprimir, alterar, eludir o manipular cualquier dispositivo de protección o sistema de seguridad que estuviera instalado en las páginas de <em>Duet Creix Happinenss,</em>.</p>
          <br />
          <br />

          <h3>Acciones legales, legislación aplicable y jurisdicción</h3><br />
          <p><em>Duet Creix Happinenss,</em> se reserva, asimismo, la facultad de presentar las acciones civiles o penales que considere oportunas por la utilización indebida de su sitio web y contenidos, o por el incumplimiento de las presentes condiciones.</p>
          <p>La relación entre el usuario y el prestador se regirá por la normativa vigente y de aplicación en el territorio español. De surgir cualquier controversia las partes podrán someter sus conflictos a arbitraje o acudir a la jurisdicción ordinaria cumpliendo con las normas sobre jurisdicción y competencia al respecto. <em>Duet Creix Happinenss,</em> tiene su domicilio en Carrer Cuba 7 - 07006, Portitxol.</p>
          <br />
          <br />
        </div>
    </section>
  );
}

export default LegalNotice;
