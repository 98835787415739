// eslint-disable-next-line import/no-anonymous-default-export
export default {
  "children.workshops": "Children workshops",
  "children.workshops.description": "Improve communication with your children and bond whilst taking part in fun activities for the whole family, such as storytelling, crafts, traditional games etc.",

  "common.home": "Home",
  
  "contact.address.title": "Address",
  "contact.contact.us": "Contact us",
  "contact.email.title": "Email",
  "contact.phone.title": "Phone",
  "contact.timetable": "Opening hours",
  "contact.timetable.lines": "Monday to Friday from 8AM to 5PM",

  "contact.form.name": "name and surname*",
  "contact.form.email": "email",
  "contact.form.subject": "subject",
  "contact.form.phone": "phone",
  "contact.form.message": "message",
  "contact.form.send": "send message",

  "duetsports": "Train you body whilst your child enjoys learning at ",

  "header.about": "about Happinenss",
  "header.children.workshops": "children workshops",
  "header.contact": "contact",
  "header.filosophy": "happinenss philosophy",
  "header.home": "home",
  "header.nursery": "early childhood",
  "header.parent.school": "School for parents",
  "header.playroom": "play area",
  "header.services": "services",
  "header.space": "our space",
  "header.vacation.campus": "holiday camps",

  "filosophy.filosophy": "Happinenss philosophy",
  "filosophy.what.is.happinenss": "What is Happinenss?",
  "filosophy.what.is.happinenss.description": `Duet Creix, the adventure of growing up… 

  We are two mothers and close friends convinced that the most precious part of society are our children.

  Duet Creix Happinenss Nursery is fruit of many years’ experience and training in meeting the different daily needs of families and individuals.

  With this new project, we aim to do our bit to accompany your children during the early stages of childhood. The first years of a child’s life are vital in marking a difference for their future. It is for that reason that we pay special attention to the environment they grow up in. 

  We live in society in which, little by little, we are losing the ability to coexist, which makes us increasingly vulnerable. Forming part of a group helps us develop certain values and abilities that make us significantly stronger as individuals.

  A nursery is where children receive love and affection from others, helping them grow up in a secure environment which will impact on their ability to face their fears, whilst learning to manage their emotions and resolve conflicts assertively.
  `,

  "footer.all.rights.reserved": "All rights reserved | An idea of",
  "footer.about.us": "About us",
  "footer.about.us.description": "Happinenss nursery focuses on making learning fun for the youngest members of the family.",
  "footer.contact": "contact",
  "footer.filosophy": "Happinens philosophy",
  "footer.legal": "Legal Notice",
  "footer.space": "Our space",
  "footer.media.policy": "Social Media Policy",

  "footerContact.do.contact": "contact",
  "footerContact.text": "Leave us a message and we will contact you",

  "footerThreeSquares.creativity": "We foster children’s creativity",
  "footerThreeSquares.professionals": "EXPERIENCED AND PASSIONATE PROFESSIONALS",
  "footerThreeSquares.space": "A SPACE CREATED WITH CHILDREN IN MIND",

  "home.about": "ABOUT HAPPINENSS",
  "home.article.1.title": "We are experts in the education and care of your children.",
  "home.article.1.text": "In our children's hatch at Portitxol we have professionals qualified in early childhood education and with extensive experience in the sector.",
  "home.article.2.title": "At Happinens we educate your children by working on emotional education.",
  "home.article.2.text": "In the nursery we want to promote the transversal development in the little ones, promoting skills and personal capacities in each one. We want to accompany children in the identification and expression of their own emotions and help them to recognize them in others as well.",
  "home.article.3.title": "We help promote healthy habits in your children.",
  "home.article.3.text": "We help the little ones to establish some routines to help form some habits. Our intention is to promote healthy habits both in food, hygiene and health, as well as at a social, affective and psychological level.",
  "home.article.4.title": "At Happinenss we believe in the importance of values.",
  "home.article.4.text": "In our children's hatch we attach great importance to education in values such as solidarity, respect and tolerance. We believe it is critical that children grow up internalizing these values.",
  "home.children.workshops": "children workshops",
  "home.children.workshops.description": "Stories, art, fun and education, all in an activity-packed space which fosters the unity of the family group, collaborative learning and above all, having fun with your loved ones.",
  "home.more.info": "More info",
  "home.parent.school": "school for parents",
  "home.parent.school.description": "A training space in which we discuss the issues facing parents today and carry out a wide variety of activities with the help of a trained professional.",
  "home.playroom": "play area",
  "home.playroom.description": "A space that provides support to families and guidance during the incredible rollercoaster ride that is raising a child!",
  "home.nursery": "ealy childhood",
  "home.nursery.description": "We are a nursery in Palma that offers a friendly and caring setting in which children grow up with a feeling of safety and security that will help them face their fears. This is the quite simply the perfect place to grow up.",
  "home.services": "services",
  "home.services.description": "At Happinenss nursery, the happiness of our little boys and girls comes from promoting their transversal development, whilst focusing on their individual abilities and skills.",
  "home.title": "Happinenss, your children's nursery in Palma",
  "home.vacation.campus": "holiday camps",
  "home.vacation.campus.description": "The school holidays don’t have to be boring! We offer holiday camps for children between 3 and 5 years of age.",
  "home.what.is.happinenss": "What is Happinenss?",
  "home.what.is.happinenss.description": "Happinenns is the nursery for your children between 4 months and 3 years in Portitxol, in Palma. Families choose us because we understand their need to be heard and seek support in the difficult but fascinating task of educating a child. To help families in the fun adventure of children's education we offer a safe and dynamic space in which the little ones will learn and have fun on a daily basis.",

  "nursery.feature.1.title": "familiar setting",
  "nursery.feature.1.description": "Friendly, familial setting, in which children develop in a caring environment.",
  "nursery.feature.2.title": "safety first",
  "nursery.feature.2.description": "A safe and dynamic space for children from as little as 4 months up to 3 years of age.",
  "nursery.feature.3.title": "extended opening hours",
  "nursery.feature.3.description": "We’re open from 8AM to 5PM. ",
  "nursery.feature.4.title": "body expression",
  "nursery.feature.4.description": "We work specifically on psychomotor activity and body expression to incentivise communication and a better understanding of the body.",
  "nursery.feature.5.title": "activities in english",
  "nursery.feature.5.description": "Daily activities in English",
  "nursery.feature.6.title": "outside area",
  "nursery.feature.6.description": "Outside play area to have fun in the fresh air.",
  "nursery.feature.7.title": "early stimulation",
  "nursery.feature.7.description": "Motivation is our methodology.",
  "nursery.nursery": "early childhood",
  "nursery.nursery.title": "early childhood",
  
  "parents.school": "School for parents",
  "parents.school.description": "Infant feeding, managing emotions, positive communication, limits and standards, and anything else that might bother you!",

  "playroom.playroom": "Play area",
  "playroom.text.1": "Your little one, in good hands.",
  "playroom.text.2": "Workshops for children",
  "playroom.text.3": "Board games and crafts",
  "playroom.text.4": "Extracurricular activities",
  "playroom.text.5": "Learn English through play",
  "playroom.text.6": "Sporting activities",

  "playroom.opening.hours": "Opening hours",
  "playroom.timetable": "From Monday to Friday 8AM to 5PM",

  "space.our.space": "Our space ",
  "space.know.our.space": "Discover our space ",
  "space.know.our.space.description": "A space in which cooperation and collaboration are at the root of all our activities, prepared with children’s safety in mind, with age-appropriate techniques and materials.",

  "vacation.campus": "Holiday camps",
  "vacation.campus.description": "The best time to establish new relationships and socialize through play and fun.\nEnglish, sporting activities, music, skill games, etc. We'll have a great time!",
};
