import React, { CSSProperties } from "react";

import { Link, useLocation } from "react-router-dom";

export type Props = {
  languageCode: string;
  languageCodeStr?: string;
  currentLanguageCode: string;
  style?: CSSProperties;
  setLanguageCode: (languageCode: string) => void;
  onSelect?: () => void;
}

function LanguageSelector(props: Props) {
  const { languageCode, languageCodeStr, currentLanguageCode, style, setLanguageCode } = props;
  const newLocation = {...useLocation() };
  newLocation.search=`lang=${languageCode}`;

  const onClickHandler = (ev: React.MouseEvent) => {
    if (currentLanguageCode === languageCode) {
      ev.stopPropagation();
      ev.preventDefault();
      return;
    }
    if (props.onSelect) {
      props.onSelect();
    }
    setLanguageCode(languageCode);
  };

  return (
    <li style={style} className={languageCode === currentLanguageCode ? "current" : ""}>
      <Link to={newLocation} onClick={onClickHandler}>
        <span>
          <img width={30} height={15} src={`/assets/img/icon/${languageCode}.jpg`} alt={languageCodeStr}/>
            {(languageCodeStr || languageCode).toUpperCase()}
        </span>
      </Link>
    </li>
  )
}

export default LanguageSelector;
