// eslint-disable-next-line import/no-anonymous-default-export
export default {
  "children.workshops": "Talleres infantiles",
  "children.workshops.description": "Mejorar la comunicación con sus hijos y los vínculos mientras participan en actividades divertidas para toda la familia, como cuentos, artesanías, juegos tradicionales, etc.",

  "common.home": "Inicio",
  
  "contact.address.title": "Dirección",
  "contact.contact.us": "Contáctanos",
  "contact.email.title": "Email",
  "contact.phone.title": "Teléfono",
  "contact.timetable": "Horarios",
  "contact.timetable.lines": "Lunes a Viernes de 08 a 17h",

  "contact.form.name": "nombre y apellidos",
  "contact.form.email": "email",
  "contact.form.subject": "asunto",
  "contact.form.phone": "teléfono",
  "contact.form.message": "mensaje",
  "contact.form.send": "enviar mensaje",

  "duetsports": "Ejercita tu cuerpo mientras tu pequeño está disfutando y aprendiendo en ",

  "header.about": "sobre Happinenss",
  "header.children.workshops": "talleres infantiles",
  "header.contact": "contacto",
  "header.filosophy": "filosofía happinenss",
  "header.home": "inicio",
  "header.nursery": "1ª infancia",
  "header.parent.school": "escuela de padres",
  "header.playroom": "ludotecta",
  "header.services": "servicios",
  "header.space": "nuestro espacio",
  "header.vacation.campus": "campus vacacionales",

  "filosophy.filosophy": "Filosofía Happinenss",
  "filosophy.what.is.happinenss": "¿Qué es Happinenss?",
  "filosophy.what.is.happinenss.description": `Duet Creix, la aventura de crecer...

  Abrimos nuestras puertas abriendo también los corazones de dos madres y 
  amigas convencidas de que el bien más preciado de una sociedad son sus 
  niños.

  Guardería Duet Creix Happinenss, nace tras varios años de formación y 
  experiencia y pretende dar respuesta a las diferentes necesidades personales 
  y familiares que se presentan a diario.

  Con este proyecto deseamos poder aportar nuestro pequeño granito de arena y 
  acompañar a vuestros hijos en esta primera etapa de su vida.
  Los primero años de crianza de un niño marcarán su futuro de forma 
  significativa. Es por ello que debemos prestar especial atención al entorno 
  que los rodea.

  Vivimos en una sociedad en la que poco a poco hemos perdido la capacidad de 
  convivencia, y eso, nos hace más vulnerables. El grupo nos ayuda a 
  desarrollar ciertas capacidades y valores que nos hacen más fuertes como 
  individuos.

  La guardería es un entorno en el que el niño reciba cariño y afecto del 
  grupo que lo rodea, hará que crezca más seguro y le ayudará a enfrentarse a 
  sus miedos. Aprenderá a gestionar mejor sus emociones y a resolver posibles 
  conflictos de forma asertiva.	
  `,

  "footer.all.rights.reserved": "Todos los derechos reservados | Una idea de",
  "footer.about.us": "Sobre nosotros",
  "footer.about.us.description": "Guardería Happinenss es un proyecto dedicado a convertir la felicidad en el acompañante perfecto para el crecimiento de los más pequeños.",
  "footer.contact": "contacto",
  "footer.filosophy": "filosofía happinenss",
  "footer.legal": "Aviso legal",
  "footer.space": "nuestro espacio",
  "footer.media.policy": "Política de redes sociales",

  "footerContact.do.contact": "contactar",
  "footerContact.text": " Déjenos un mensaje y contactaremos con usted",

  "footerThreeSquares.creativity": "Fomentamos la creatividad en los pequeños",
  "footerThreeSquares.professionals": "PROFESIONALES CON EXPERIENCIA Y PASIÓN",
  "footerThreeSquares.space": "UN ESPACIO ACONDICIONADO PARA NIÑOS",

  "home.about": "SOBRE HAPPINENSS",
  "home.article.1.title": "Somos expertos en la educación y el cuidado de tus hijos.",
  "home.article.1.text": "En nuestra escoleta infantil en el Portitxol contamos con profesionales titulados en educación infantil y con amplia experiencia en el sector. Una de nuestras educadoras es de habla inglesa.",
  "home.article.2.title": "En Happinens educamos a tus hijos trabajando la educación emocional.",
  "home.article.2.text": "En la guardería infantil queremos favorecer el desarrollo transversal en los más pequeños, impulsando habilidades y capacidades personales en cada uno. Queremos acompañar a los niños en la identificación y expresión de sus propias emociones y ayudarles a reconocerlas también en los demás.",
  "home.article.3.title": "Ayudamos a promover hábitos saludables en tus hijos.",
  "home.article.3.text": "Ayudamos a los más pequeños a establecer unas rutinas para contribuir a formar unos hábitos. Nuestra intención es promover hábitos saludables tanto en la alimentación, higiene y salud, como a nivel social, afectivo y psicológico.",
  "home.article.4.title": "En Happinenss creemos en la importancia de los valores.",
  "home.article.4.text": "En nuestra escoleta infantil damos gran importancia a la educación en valores como la solidaridad, el respeto y la tolerancia. Creemos que es fundamental que los niños crezcan interiorizando estos valores.",
  "home.children.workshops": "talleres infantiles",
  "home.children.workshops.description": "Cuentos, arte, diversión y formación, todo un espacio repleto de actividades que fomenten la unión familiar, la  formación en equipo y la diversión con los tuyos.",
  "home.more.info": "Más info",
  "home.parent.school": "escuela de padres",
  "home.parent.school.description": "Espacio formativo destinado a dar respuesta a las inquietudes de los padres, contando para ello con la colaboración de personal cualificado para las diferentes actividades que llevaremos a cabo.",
  "home.playroom": "ludoteca",
  "home.playroom.description": "Un espacio que apuesta por el apoyo y la orientación a las familias en la apasionante aventura de la crianza de un hijo.",
  "home.nursery": "1ª infancia",
  "home.nursery.description": "Guardería en Palma, un entorno en el que el niño reciba cariño y afecto del grupo que lo rodea, hará que crezca más seguro y le ayudará a enfrentarse a sus miedos. Éste es un lugar ideal para su crecimiento.",
  "home.services": "Servicios",
  "home.services.description": "En la guardería Happinenss conseguimos la felicidad de cada niño favoreciendo su desarrollo transversal e impulsando las diferentes habilidades y capacidades personales de cada uno.",
  "home.title": "Happinenss, la guardería de tus hijos en Palma",
  "home.vacation.campus": "campus vacacionales",
  "home.vacation.campus.description": "Toda una experiencia dirigida a niños entre 3 y 5 años para disfrutar aún más de sus vacaciones escolares.",
  "home.what.is.happinenss": "¿Qué es Happinenss?",
  "home.what.is.happinenss.description": "Happinenns es la guardería para tus hijos de entre 4 meses y 3 años en el Portitxol, en Palma. Las familias nos eligen porque entendemos la necesidad que tienen de ser escuchados y de buscar apoyos en la difícil pero fascinante tarea de educar a un hijo. Para ayudar a las familias en la divertida aventura de la educación de los hijos ofrecemos un espacio seguro y dinámico en el que los más pequeños aprenderán y se divertirán diaramente.",

  "nursery.feature.1.title": "ambiente familiar",
  "nursery.feature.1.description": "Ambiente familiar donde los peques crecen rodeados de cariño.",
  "nursery.feature.2.title": "seguridad",
  "nursery.feature.2.description": "Espacio seguro y dinámico acondicionado para pequeños de cuatro meses a 3 años.",
  "nursery.feature.3.title": "ámplio horario",
  "nursery.feature.3.description": "Ámplio horario: de 8h a 17h.",
  "nursery.feature.4.title": "expresión corporal",
  "nursery.feature.4.description": "La psicomotricidad y la expresión corporal la trabajaremos para incentivar la comunicación y comprensión del propio cuerpo.",
  "nursery.feature.5.title": "actividades en inglés",
  "nursery.feature.5.description": "Actividades diarias en inglés.",
  "nursery.feature.6.title": "zona exterior",
  "nursery.feature.6.description": "Zona exterior para disfrutar de juego al aire libre.",
  "nursery.feature.7.title": "estimulación temprana",
  "nursery.feature.7.description": "La motivación es nuestro método.",
  "nursery.nursery": "1ª infancia",
  "nursery.nursery.title": "espacio 1ª infancia",
  
  "parents.school": "Escuela de padres",
  "parents.school.description": "Alimentación infantil, gestión de las emociones, comunicación positiva, límites y normas, y todo lo que te pueda preocupar.",

  "playroom.playroom": "Ludoteca",
  "playroom.text.1": "Tu pequeño en las mejores manos",
  "playroom.text.2": "Talleres infantiles",
  "playroom.text.3": "Juegos de mesa y manualidades",
  "playroom.text.4": "Actividades extraescolares",
  "playroom.text.5": "Inglés aprende jugando",
  "playroom.text.6": "Actividades deportivas",

  "playroom.opening.hours": "Horario",
  "playroom.timetable": "De lunes a viernes de 08h a 17h",

  "space.our.space": "Nuestro espacio",
  "space.know.our.space": "Conoce nuestro espacio",
  "space.know.our.space.description": "Un espacio en el que la cooperación y la ayuda mutua sean el eje de nuestras actividades preparado para priorizar la seguridad de los más pequeños con técnicas y materiales especialmente pensados para su edad.",

  "vacation.campus": "Campus vacacionales",
  "vacation.campus.description": "El mejor momento para establecer nuevas relaciones y socializar a través del juego y la diversión.\n Inglés, actividades deportivas, música, juegos de habilidades, etc. ¡Lo pasaremos genial!",
};
