import React, { useEffect } from "react";

export type Props = {
  imageSrc: string;
  text: string;
}

function NivoSlider(props: Props) {
  const { imageSrc, text } = props;

  useEffect(() => {
    const oldScript = document.getElementById("nivoScript");
    if (oldScript) {
      document.body.removeChild(oldScript);
    }
    const script = document.createElement("script");
    script.async = true;
    script.id="nivoScript"
    script.innerHTML = `
    $('#ensign-nivoslider').nivoSlider({
      effect: 'random',
      slices: 15,
      boxCols: 8,
      boxRows: 4,
      animSpeed: 500,
      pauseTime: 5000,
      startSlide: 0,
      directionNav: true,
      controlNavThumbs: false,
      pauseOnHover: true,
      manualAdvance: false
     });    
    `;
    document.body.appendChild(script);
    }, [text]);

  return (
    <section className="slider-area slide-v2">
      <div className="bend niceties preview-2">
        <div id="ensign-nivoslider" className="slides">	
        <img src={imageSrc} alt="guardería Palma" title="#slider-direction-1"  />
      </div>
      <div id="slider-direction-1" className="slider-direction slider-one">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="slider-content">
                <div className="layer-1-2">
                  <h5 id="word" className="title2"><span>{text}</span></h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  );
}

export default NivoSlider;
